/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types, no-new-func */
import { isEmpty } from 'ramda';
import { UI_BUILDER_MODES } from '../providers/DashboardProvider';
import { throttledLogWarn, createFunction } from './createFunction';
import { isRenderMoreHooksError } from './isRenderMoreHooksError';
export const createAndReturnFunction = (functionCode, appRuntimeStateList, uiBuilderMode, options, args) => {
    var _a;
    const hookArguments = args || {};
    const librariesUsedInCode = Object.values((options === null || options === void 0 ? void 0 : options.libraries) || {}).filter(lib => functionCode === null || functionCode === void 0 ? void 0 : functionCode.includes(lib.alias));
    try {
        const { predefinedState, globalState: appState, localState, temporaryState, localStates, } = appRuntimeStateList;
        const state = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, predefinedState), appState), localState), temporaryState), localStates), hookArguments);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return createFunction(functionCode, state, librariesUsedInCode, !!(options === null || options === void 0 ? void 0 : options.isNewFunction));
    }
    catch (err) {
        throttledLogWarn(`\n\n_____Runtime Function Error_____\n\n\n`, err, `_____Function Code___`, functionCode);
        (_a = options === null || options === void 0 ? void 0 : options.onAppFunctionError) === null || _a === void 0 ? void 0 : _a.call(options, err);
        if (uiBuilderMode === UI_BUILDER_MODES.preview && err.name !== 'ReferenceError') {
            appRuntimeStateList.predefinedState.history.push('/500');
            const errorState = appRuntimeStateList.globalState.uncaughtErrors;
            if (!isEmpty(errorState.value[0].error)) {
                errorState.setValue([
                    ...errorState.value,
                    {
                        message: 'Runtime Function Error',
                        error: err,
                        code: functionCode,
                    },
                ]);
            }
            else {
                errorState.setValue([
                    {
                        message: 'Runtime Function Error',
                        error: err,
                        code: functionCode,
                    },
                ]);
            }
        }
        // if hook is added then the need to catch this error on the top of AppEngine anr remount it
        if ((options === null || options === void 0 ? void 0 : options.throwReactRenderError) && isRenderMoreHooksError(err.message)) {
            throw err;
        }
    }
};
